import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useParams, Link } from 'react-router-dom';

import { IoMdCloseCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";


import { tableTitleClass } from '../themes' 


import { useAuth } from './../auth/AuthProvider';

export default function InvoicesList() {

    const [invoices, setInvoices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage] = useState(20);

    const { pageNumber } = useParams();

    const { user } = useAuth();

    useEffect(() => {
        Axios.get(
            process.env.REACT_APP_SERVER_BACKEND + '/faktury',
        ).then((res) => {
            if (res.data && Array.isArray(res.data.results)) {
                setInvoices(res.data.results);
            } else {
                console.log(res);
                // console.log('aaa');
                setInvoices([]); // Ustawienie pustej tablicy, jeśli odpowiedź nie zawiera oczekiwanych danych
            }
        }).catch((err) => {
            console.error("Błąd podczas pobierania faktur:", err);
            setInvoices([]); // Ustawienie pustej tablicy w przypadku błędu
        });
    }, [pageNumber]);
    


    useEffect(() => {
        if(pageNumber && !isNaN(pageNumber)) {
            const page = parseInt(pageNumber);
            const totalPages = Math.ceil(invoices.length / invoicesPerPage);
            setCurrentPage(Math.min(page, totalPages));
        }
    }, [pageNumber, invoices, invoicesPerPage]);


    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    // const currentInvoices = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
    const currentInvoices = invoices ? invoices.slice(indexOfFirstInvoice, indexOfLastInvoice) : [];


    // Zmiana strony
    const paginate = pageNumber => {
        const totalPages = Math.ceil(invoices.length / invoicesPerPage);
        const page = Math.min(pageNumber, totalPages);
        setCurrentPage(page);
    };


    const removeInvoice = (e, productId) => {
        e.preventDefault();  
        if (window.confirm("Potwierdź usunięcie faktury")) { 
            Axios.delete(
                `${process.env.REACT_APP_SERVER_BACKEND}/faktury/${productId}`
            ).then((res) => { 
                if(res.data.success) { 
                    Axios.get(
                        process.env.REACT_APP_SERVER_BACKEND + '/faktury',
                    ).then((res) => {
                        setInvoices(res.data.results);
        
                        const totalPages = Math.ceil(res.data.results.length / invoicesPerPage);
                        setCurrentPage(Math.min(currentPage, totalPages));
                    });
                    alert('Faktura została usunięta.')
                } else {
                    alert('Brak uprawnień do usunięcia faktury')
                }
            });
        }
    }
    

    const tdStyle = 'py-2 px-4';

    return (
        <div>
            <div className="flex justify-end mb-5 mt-2">
                <Link to={'/faktury/dodaj'} className="rounded-lg bg-orange-600 text-white py-2 px-5">Dodaj nową fakturę</Link>
            </div>
            <div className="bg-white rounded-lg px-3 py-2">
                <table className="w-full text-gray-800 text-center text-sm">
                    <thead>    
                        <tr>
                            <th className={`product_lp ${tableTitleClass}`}>Lp.</th>
                            <th className={`product_name text-left ${tableTitleClass}`}>Nazwa</th>
                            <th className={`${tableTitleClass} px-4 product_action`}>
                                Akcje
                            </th>
                        </tr>
                    </thead>  
                    <tbody>     
                        {invoices && 
                            currentInvoices.map((invoice, index) => (
                                <tr key={index}>
                                    <td className={`w-px whitespace-nowrap ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {index + 1}
                                    </td>
                                    <td className={`text-left w-full ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {invoice.invoice_name}
                                    </td>
                                    <td  className={`w-px whitespace-nowrap ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        <div className="flex items-center justify-center">
                                            <Link to={`/faktury/edytuj/${invoice.id}`} className='ms-1 me-1'>
                                                <MdEdit />
                                            </Link>     
                                            {user.role === 'admin' && (
                                            <a href="!#" onClick={(e) => removeInvoice(e, invoice.id)} className='text-xl text-red-600 ms-1 me-1'>
                                                <IoMdCloseCircle />
                                            </a>                           
                                            )}                
                                        </div>
                                    </td>
                                </tr>
                        ))}         
                    </tbody>       
                </table>
            </div>
            <Pagination
                invoicesPerPage={invoicesPerPage}
                totalInvoices={invoices.length}
                currentPage={currentPage}
                paginate={paginate}                
            />
        </div>
    )
}


const Pagination = ({ invoicesPerPage, totalInvoices, currentPage, paginate }) => {
    const pageNumbers = [];

    for( let i = 1; i <= Math.ceil( totalInvoices / invoicesPerPage ); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination mt-4">
            <ul className="flex flex-wrap justify-center">
                { pageNumbers &&
                    pageNumbers.map(number => (

                        <li key={number} className={`page-item m-1 ${number === currentPage ? 'active' : ''}`}>
                            <Link to={`/faktury/${number}`} className={`m-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                                {number}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
    
}
// fajnie byloby wyniesc to jako komponent