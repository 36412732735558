import React, { useState, useEffect } from 'react';
import Axios from "axios";

const InputOrder = ({ ordersSummary, setOrdersSummary, initialOrderNumber, onChangeOrderNumber }) => {
  const [orderNumber, setOrderNumber] = useState(initialOrderNumber || '');
  const [prevOrderNumber, setPrevOrderNumber] = useState(initialOrderNumber || '');

  useEffect(() => {
    setOrderNumber(initialOrderNumber);
    setPrevOrderNumber(initialOrderNumber); 
  }, [initialOrderNumber]);

  const handleChangeNumber = (e) => {
    const currentOrderNumber = e.target.value;
    const currentPositionInvoice = e.target.closest('tr');
    if (!currentPositionInvoice) return;

    setOrderNumber(currentOrderNumber);

    // Wywołaj funkcję do aktualizacji
    onChangeOrderNumber(currentOrderNumber);
    
    const bruttoValue = currentPositionInvoice.querySelector('td[data-name="brutto"] input').value.trim();
    const bruttoValueFixed = bruttoValue.replace(',', '.');
    const positionBrutto = parseFloat(bruttoValueFixed).toFixed(2);


    const id = currentPositionInvoice.dataset.product_id;

    const updatedProduct = {
      product_order: currentOrderNumber
    }
    Axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/produkty/zamowienie/${id}`, updatedProduct)
    .then((res) => {
      console.log(res.data)
    })
    .catch((error) => {
      console.log(error)
    })


    setOrderNumber(currentOrderNumber);

    let updatedOrdersSummary = ordersSummary;

    if (prevOrderNumber) {
      const prevOrderIndex = updatedOrdersSummary.findIndex(order => order.zamowienie === prevOrderNumber);

      if (prevOrderIndex !== -1) {
        const updatedBrutto = (parseFloat(updatedOrdersSummary[prevOrderIndex].brutto) - parseFloat(positionBrutto)).toFixed(2);
        
        if (parseFloat(updatedBrutto) > 0) {
          updatedOrdersSummary[prevOrderIndex].brutto = updatedBrutto;
        } else {
          updatedOrdersSummary.splice(prevOrderIndex, 1);
        }
      }
    }

    if (currentOrderNumber) {
      const currentOrder = {
        zamowienie: currentOrderNumber,
        brutto: positionBrutto,
      };

      const existingOrderIndex = updatedOrdersSummary.findIndex(order => order.zamowienie === currentOrderNumber);

      if (existingOrderIndex !== -1) {
        const newOrdersSummary = updatedOrdersSummary.map((order, index) => {
          if (index === existingOrderIndex) {
            return {
              ...order,
              brutto: (parseFloat(order.brutto) + parseFloat(positionBrutto)).toFixed(2),
            };
          }
          return order;
        });
        setOrdersSummary(newOrdersSummary);
      } else {
        setOrdersSummary([...updatedOrdersSummary, currentOrder]);
      }
    }

    setPrevOrderNumber(currentOrderNumber);
  };

  return (
    <input
      type="number"
      name="orderNumber"
      className="border border-gray-300 text-slate-900 bg-gray-50 w-16 p-1 text-xs text-center"
      onChange={handleChangeNumber}
      value={orderNumber|| ''}
    />
  );
};

export default InputOrder;
