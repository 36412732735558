import React from "react"
import { Navigate, Outlet, Link } from "react-router-dom"
import { useAuth } from "./auth/AuthProvider"

import { LiaFileInvoiceSolid, LiaHomeSolid, LiaShoppingCartSolid } from "react-icons/lia"
import { RiLogoutBoxLine } from "react-icons/ri"

const PrivateRoute = () => { 
    const { token, logoutAction } = useAuth();
    if (!token) return <Navigate to="/login" />;


    const menu = [ 
        {
          icon: <LiaHomeSolid />,
          link: 'kokpit',
          text: 'Kokpit'
        },
        {
          icon: <LiaFileInvoiceSolid />,
          link: 'faktury',
          text: 'Faktury'
        },
        {
          icon: <LiaShoppingCartSolid />,
          link: 'produkty',
          text: 'Produkty'
        }, 
    ]

    return (
        <div className="flex flex-row h-screen">
          <aside className="bg-gray-800 text-white px-2 main__menu py-3">
            <ul className="space-y-2">
              {menu.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} className="flex items-center rounded-lg hover:bg-gray-900 group p-2">
                    {item.icon}              
                    <span className="ms-3">{item.text}</span>
                  </Link>
                </li>
              ))}
              <li> 
                <button 
                  onClick={logoutAction} 
                  className="flex items-center rounded-lg hover:bg-gray-900 group p-2 w-full text-left"
                >
                  <RiLogoutBoxLine />
                  <span className="ms-3">Wyloguj</span>
                </button>
              </li>
            </ul>
          </aside>
          <div className="overflow-y-auto main__content">
            <main className="container max-auto px-4 py-3">
              <Outlet />
            </main>
          </div>
        </div> 
    );
}

export default PrivateRoute; 