import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import { btnClass } from '../themes'
import { useParams } from 'react-router-dom'

export default function ProductEdit() {

    const { id } = useParams();

    const [productName, setProductName] = useState('')
    const [productPrice, setProductPrice] = useState('')
    const [productOurName, setProductOurName] = useState('')
    const [message, setMessage] = useState('')
    const [messageIsError, setMessageIsError] = useState(false)

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_SERVER_BACKEND}/produkty/${id}`)
        .then((res) => {
            setProductOurName(res.data.results[0].our_name);
            setProductName(res.data.results[0].product_name);
            setProductPrice(res.data.results[0].product_price);
        })
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault();
        if(productName !== '' && productPrice !== '') {
            const updatedProduct = {
                our_name: productOurName,
                product_name: productName,
                product_price: productPrice
            }
            Axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/produkty/${id}`, updatedProduct)
            .then((res) => {
                // console.log(res.data)
                setMessage('Zmiany zostały zapisane')
                setMessageIsError(false)
            })
            .catch((error) => {
                // console.log(error)
                setMessage('Ups... coś poszło nie tak.')
                setMessageIsError(true)
            })
        } else {
            setMessage('Najpierw musisz wypełnić wszystkie pola.')
            setMessageIsError(true)
        }

        setTimeout(() => {
            setMessage('')
            setMessageIsError(false)
        }, 5000)
    }

    const labelClass = 'text-lg'
    const inputClass = 'w-5/6 border-0 border-b border-gray-300 text-slate-900 bg-gray-50 p-3 text-base'

    return (
        <div className='h-lvh flex justify-center items-center flex-col'>
            {message && <p className={`mb-3 text-center p-3 text-sm text-white w-full max-w-screen-sm shadow-md rounded-lg ${messageIsError ? 'bg-red-700' : 'bg-green-700'}`}>{message}</p>}
            <form action="" className='bg-white px-8 py-8 rounded-lg shadow-md w-full max-w-screen-sm'>
                <div className='flex items-center mb-5'>
                    <label htmlFor="product_name" className={`${labelClass} w-2/6 text-right pe-5`}>Nazwa własna:</label>
                    <input 
                        id="our_name"
                        type="text" 
                        name="our_name"
                        placeholder='Wpisz własną nazwę'
                        className={inputClass}
                        value={productOurName}
                        onChange={(event) => {
                            setProductOurName(event.target.value.replace(/\s+/g, ' ').trim())
                        }}
                    />
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="product_name" className={`${labelClass} w-2/6 text-right pe-5`}>Nazwa produktu:</label>
                    <input 
                        id="product_name"
                        type="text" 
                        name="product_name"
                        placeholder='Wpisz nazwę wg hurtownii'
                        className={inputClass}
                        value={productName}
                        onChange={(event) => {
                            setProductName(event.target.value.replace(/\s+/g, ' ').trim())
                        }}
                    />
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="product_name" className={`${labelClass} w-2/6 text-right pe-5`}>Cena netto:</label>
                    <input
                        id="product_price"
                         type="number" 
                         min="0.00" 
                         max="10000.00" 
                         step="0.01"
                         placeholder='10.00'
                         className={inputClass}
                         value={productPrice}
                         onChange={(event) => {
                            setProductPrice(event.target.value)
                         }}
                    />
                </div>
                <div className='flex justify-end'>
                    <button className={btnClass} onClick={handleSubmit}>Zapisz zmiany</button>
                </div> 
            </form>
        </div>
    )
}