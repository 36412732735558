import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { CSVImporter } from "csv-import-react";

export default function ProductsCSV() {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleCSVComplete = (products) => {
        console.log('first');
        console.log(products.rows);
        if (products.rows.length > 0) { 
            Axios.post(
                `${process.env.REACT_APP_SERVER_BACKEND}/import-produktow`,
                { products }
            ).then((res) => { 
                setIsOpen(false);
                setMessage('Produkty zostały przesłane pomyślnie.');
                setTimeout(() => setMessage(''), 10000);
            }).catch((err) => { 
                // setError('Wystąpił błąd podczas przesyłania produktów.');
                // setTimeout(() => setError(''), 3000);
            });
        }
    };

    return (
        <div>
            <div className="bg-white rounded-lg px-3 py-2">
                <button onClick={() => setIsOpen(true)}>Open CSV Importer</button>
 
                {message && <p className="text-green-500">{message}</p>}
                {error && <p className="text-red-500">{error}</p>}

                <CSVImporter
                    language="pl"
                    modalIsOpen={isOpen}
                    modalOnCloseTriggered={() => setIsOpen(false)}
                    darkMode={true}
                    onComplete={handleCSVComplete}
                    template={{
                        columns: [
                            { name: 'Nasza nazwa', key: 'nasza_nazwa' },
                            { name: 'Nazwa hurtowni', key: 'nazwa_hurtowni' },
                            { name: 'Cena', key: 'cena' },
                        ],
                    }}
                />
            </div>
        </div>
    );
}
