import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useParams, Link } from 'react-router-dom';
import { CSVImporter } from "csv-import-react";

import { IoMdCloseCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";


import { tableTitleClass } from '../themes' 

export default function ProductsList() {

    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(100);

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const { pageNumber } = useParams();

    useEffect(() => {
        Axios.get(
            process.env.REACT_APP_SERVER_BACKEND + '/produkty',
        ).then((res) => {
            setProducts(res.data.results);
        });
    }, [pageNumber]);


    useEffect(() => {
        if(pageNumber && !isNaN(pageNumber)) {
            const page = parseInt(pageNumber);
            const totalPages = Math.ceil(products.length / productsPerPage);
            setCurrentPage(Math.min(page, totalPages));
        }
    }, [pageNumber, products, productsPerPage]);


    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    // Zmiana strony
    const paginate = pageNumber => {
        const totalPages = Math.ceil(products.length / productsPerPage);
        const page = Math.min(pageNumber, totalPages);
        setCurrentPage(page);
    };


    const removeProduct = (e, productId) => {
        e.preventDefault(); 
        Axios.delete(
            `${process.env.REACT_APP_SERVER_BACKEND}/produkty/${productId}` // Dodanie productId do URL
        ).then((res) => {
            // console.log(res);
            if(res.data.success) {
                Axios.get(
                    process.env.REACT_APP_SERVER_BACKEND + '/produkty',
                ).then((res) => {
                    setProducts(res.data.results);

                    const totalPages = Math.ceil(res.data.results.length / productsPerPage);
                    setCurrentPage(Math.min(currentPage, totalPages));
                });
            }
        });
    }

    
    const handleCSVComplete = (products) => {
        // console.log(products.rows);
        if (products.rows.length > 0) { 
            Axios.post(
                `${process.env.REACT_APP_SERVER_BACKEND}/import-produktow`,
                { products }
            ).then((res) => { 
                setIsOpen(false);
                setMessage('Produkty zostały przesłane pomyślnie.');
                setTimeout(() => setMessage(''), 10000); 
                Axios.get(
                    process.env.REACT_APP_SERVER_BACKEND + '/produkty',
                ).then((res) => {
                    setProducts(res.data.results);

                    const totalPages = Math.ceil(res.data.results.length / productsPerPage);
                    setCurrentPage(Math.min(currentPage, totalPages));
                }); 

            }).catch((err) => { 
                setError('Wystąpił błąd podczas przesyłania produktów.');
                setTimeout(() => setError(''), 10000);
            });
        }
    };

    const deleteProducts = () => { 
        if (window.confirm("Potwierdź usunięcie wszystkich produktów")) { 
            Axios.delete(`${process.env.REACT_APP_SERVER_BACKEND}/produkty`)
                .then((res) => {
                    if (res.data.success) {
                        setProducts([]);
                        alert('Wszystkie produkty zostały usunięte.');
                    } else {
                        alert('Wystąpił błąd podczas usuwania produktów.');
                    }
                })
                .catch((err) => {
                    console.error("Błąd podczas usuwania produktów:", err);
                    alert('Wystąpił błąd podczas usuwania produktów.');
                });
        } else { 
            alert('Operacja anulowana.');
        }
    }

    const tdStyle = 'py-2 px-4';

    return (
        <div>
            <div className="flex justify-end mb-5 mt-2">
                <Link to={'/produkty/dodaj'} className="rounded-lg bg-orange-600 text-white py-2 px-5 mr-4">Dodaj nowy produkt</Link>
                <button className="rounded-lg bg-red-600 text-white py-2 px-5 mr-4" onClick={deleteProducts}>Usuń wszystkie produkty</button>
                <button className="rounded-lg bg-blue-600 text-white py-2 px-5" onClick={() => setIsOpen(true)}>CSV Importer</button>

                <CSVImporter
                    language="pl"
                    modalIsOpen={isOpen}
                    modalOnCloseTriggered={() => setIsOpen(false)}
                    darkMode={true}
                    onComplete={handleCSVComplete}
                    template={{
                        columns: [
                            { name: 'Nasza nazwa', key: 'nasza_nazwa' },
                            { name: 'Nazwa hurtowni', key: 'nazwa_hurtowni' },
                            { name: 'Cena', key: 'cena' },
                        ],
                    }}
                />
            </div>
            {message && <p className="rounded-lg bg-green-500 text-white py-2 px-5 mb-4 text-center">{message}</p>}
            {error && <p className="rounded-lg bg-red-500 text-white py-2 px-5 mb-4 text-center">{error}</p>}
            <div className="bg-white rounded-lg px-3 py-2">
                <table className="w-full text-gray-800 text-center text-sm">
                    <thead>    
                        <tr>
                            <th className={`product_lp ${tableTitleClass}`}>Lp.</th>
                            <th className={`own_name text-left ${tableTitleClass}`}>Nazw własna</th>
                            <th className={`product_name text-left ${tableTitleClass}`}>Nazwa hurtowni</th>
                            <th className={`${tableTitleClass} whitespace-nowrap px-4 product_price`}>Cena netto</th>
                            <th className={`${tableTitleClass} px-4 product_action`}>
                                Akcje
                            </th>
                        </tr>
                    </thead>  
                    <tbody>     
                        {products && 
                            currentProducts.map((product, index) => (
                                <tr key={index}>
                                    <td className={`w-px whitespace-nowrap ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {((currentPage - 1) * 100) + (index + 1)}
                                    </td>
                                    <td className={`text-left w-1/2 ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {product.our_name}
                                    </td>
                                    <td className={`text-left w-1/2 ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {product.product_name}
                                    </td>
                                    <td className={`w-px whitespace-nowrap ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {product.product_price.toFixed(2)} zł
                                    </td>
                                    <td  className={`w-px whitespace-nowrap ${tdStyle} ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        <div className="flex items-center justify-center">
                                            <Link to={`/produkty/edytuj/${product.id}`} className='ms-1 me-1'>
                                                <MdEdit />
                                            </Link>     
                                            <a href="!#" onClick={(e) => removeProduct(e, product.id)} className='text-xl text-red-600 ms-1 me-1'>
                                                <IoMdCloseCircle />
                                            </a>                                           
                                        </div>
                                    </td>
                                </tr>
                        ))}         
                    </tbody>       
                </table>
            </div>
            <Pagination
                productsPerPage={productsPerPage}
                totalProducts={products.length}
                currentPage={currentPage}
                paginate={paginate}                
            />
        </div>
    )
}


const Pagination = ({ productsPerPage, totalProducts, currentPage, paginate }) => {
    const pageNumbers = [];

    for( let i = 1; i <= Math.ceil( totalProducts / productsPerPage ); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination mt-4">
            <ul className="flex flex-wrap justify-center">
                { pageNumbers &&
                    pageNumbers.map(number => (                        
                        <li key={number} className={`page-item m-1 ${number === currentPage ? 'active' : ''}`}>
                            <Link to={`/produkty/${number}`} className={`m-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                                {number}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
    
}