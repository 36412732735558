import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Powróć do poprzedniej strony
  };

  return (
    <div id="error-page">
      <h1>Strona nie istnieje</h1>
      <button onClick={handleGoBack}>Wróć do poprzedniej strony</button>
    </div>
  );
}
