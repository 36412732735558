import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import * as pdfjs from 'pdfjs-dist/build/pdf';
// import InvoiceTable from './../components/Invoice/InvoiceTable';
import ListOrders from './../components/Invoice/ListOrders';
import { btnClass } from '../themes';
import axios from 'axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function InvoiceAdd() {
  const [tableData, setTableData] = useState([]);
  const [nameInvoice, setNameInvoice] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [ordersSummary, setOrdersSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const navigate = useNavigate();


  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  const handleUploadPdf = async (e) => {
    e.preventDefault();
    if (uploadedFile !== null) {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('pdfFile', uploadedFile);
        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/upload-pdf`, formData);

        const pdfData = response.data;
        setFileName(pdfData.fileName);

        // console.log(pdfData.fileName);

      } catch (error) {
        console.error('Błąd podczas przesyłania pliku PDF:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const tableRef = useRef(null);

  const handleSave = async (e) => {
      e.preventDefault();

      if (tableData.length === 0 || !fileName) {
        alert('Nie załadowano danych do zapisania!');
        return;
      }

      try {
        setIsLoading(true);

        const invoiceResponse = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/dodaj`, {
          InvoiceName: nameInvoice,
          FileName: fileName
        });

        const invoiceId = invoiceResponse.data.id; 

        if (!invoiceId) {
          alert('Nie udało się uzyskać ID faktury');
          return;
        }

        // Zmieniamy fileName na invoiceId
        const batchSize = 100;
        for (let i = 0; i < tableData.length; i += batchSize) {
          const batch = tableData.slice(i, i + batchSize);

          // Teraz wysyłamy invoiceId, a nie fileName
          await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/dodaj-pozycje`, { 
            invoice_id: invoiceId, 
            items: batch 
          });
        }

        console.log('Dane zostały pomyślnie zapisane:', invoiceId);

        // window.location.href = `/faktury/edytuj/${invoiceId}`;
        navigate(`/faktury/edytuj/${invoiceId}`);

      } catch (error) {
        console.error('Błąd podczas zapisywania danych:', error);
      }
  };


  useEffect(() => {
    const fetchPdfAndExtractTable = async () => {
      if (!fileName) return;

      try {
        const pdfUrl = `${process.env.REACT_APP_SERVER_BACKEND}/uploads/${fileName}`;
        const response = await fetch(pdfUrl);
        const buffer = await response.arrayBuffer();

        const loadingTask = pdfjs.getDocument({ data: new Uint8Array(buffer) });
        const pdfDocument = await loadingTask.promise;


      //  const iloscPattern = /^\d{1,3}(,\d{3})*(\.\d{3})?$/;
       const iloscPattern = /^\d{1,3}(\s?\d{3})*(,\d{3})?$/;


        let allPagesResults = [];

        for (let i = 1; i <= pdfDocument.numPages; i++) {
          const page = await pdfDocument.getPage(i);
          const content = await page.getTextContent();
          const items = content.items;

          const startIndex = items.findIndex(item => item.str.includes('Wartość brutto'));

          if (i === 1) {
            const indexNameInvoice = items.findIndex(item => item.str.includes('Faktura VAT'));
            setNameInvoice(items[indexNameInvoice].str);
          }

          if (startIndex !== -1) {
            let filteredItems = items.filter((item, index) => index > startIndex + 1);

            const ciagDalszyIndex = filteredItems.findIndex(item => item.str.includes('Ciąg dalszy na następnej stronie'));
            if (ciagDalszyIndex !== -1) {
              filteredItems.splice(ciagDalszyIndex - 1, 3);
            }

            const vatIndex = filteredItems.findIndex(item => item.str.includes('według stawki VAT'));
            if (vatIndex !== -1) {
              filteredItems.splice(vatIndex);
            }

            // filteredItems = filteredItems.filter(item => item.str !== '(kpl');

            // console.log(filteredItems.length);

            console.log(filteredItems);
            
            const formattedResults = [];
            for (let i = 0; i < filteredItems.length; i += 9) {
              if (
                filteredItems[i] &&
                filteredItems[i + 1] &&
                filteredItems[i + 2] &&
                filteredItems[i + 3] &&
                filteredItems[i + 4] &&
                filteredItems[i + 5] &&
                filteredItems[i + 6] &&
                filteredItems[i + 7] &&
                filteredItems[i + 8]
              ) {

                let jm, nazwa, ilosc, cena_netto, podatek, wartosc_netto, vat, brutto;

                const lp = parseInt(filteredItems[i].str.trim().replace(/\s/g, ''), 10);

                nazwa = filteredItems[i + 1].str.trim();
                ilosc = filteredItems[i + 2].str.trim();

                if (!iloscPattern.test(ilosc)) {
                  nazwa += ' ' + ilosc;
                  ilosc = filteredItems[i + 3].str.trim();
                  ilosc = parseInt(ilosc.replace(/\s/g, '')).toFixed(3);

                  jm = filteredItems[i + 4].str.trim();
                  cena_netto = filteredItems[i + 5].str.trim();
                  podatek = filteredItems[i + 6].str.trim();
                  wartosc_netto = filteredItems[i + 7].str.trim();
                  vat = filteredItems[i + 8].str.trim(); 
                  brutto = filteredItems[i + 9].str.trim();
                  i++; // Skip an extra index for the next iteration to avoid overlapping
                } else {
                  ilosc = filteredItems[i + 2].str.trim();
                  ilosc = parseInt(ilosc.replace(/\s/g, '')).toFixed(3);

                  jm = filteredItems[i + 3].str.trim();
                  cena_netto = filteredItems[i + 4].str.trim();
                  podatek = filteredItems[i + 5].str.trim();
                  wartosc_netto = filteredItems[i + 6].str.trim();
                  vat = filteredItems[i + 7].str.trim();
                  brutto = filteredItems[i + 8].str.trim();
                }

                if(nazwa.startsWith('...')) {
                  nazwa = nazwa.slice(3);
                }

                wartosc_netto = parseInt(wartosc_netto.replace(/\s/g, '')).toFixed(2);
                vat = parseInt(vat.replace(/\s/g, '')).toFixed(2);
                brutto = parseInt(brutto.replace(/\s/g, '')).toFixed(2);

                formattedResults.push({ lp, nazwa, ilosc, jm, cena_netto, podatek, wartosc_netto, vat, brutto });
              }
            }

            allPagesResults.push(...formattedResults);
          }
        }

        setTableData(allPagesResults);
      } catch (error) {
        console.error('Error loading or extracting PDF:', error);
      }
    };

    fetchPdfAndExtractTable();
  }, [fileName]);

  return (
    <>
      <div className="py-3 basis-9/12">
        <div className="rounded-xl shadow-sm px-3 py-8 bg-white text-gray-700 relative">
          {
          isLoading &&
              <>
                  <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 z-20">
                      <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                      <span className="sr-only">Loading...</span>
                  </div>
                  <div
                      className='absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-white opacity-80 z-10'></div>
              </>
          }
          <h1 className="text-2xl font-bold text-center mb-8 w-full border-b bprder-gray-700 pb-8">Dodawanie faktury</h1>

          {fileName && tableData.length > 0 && !isLoading 
              ? (
                <>
                  <p className='text-center font-bold text-green-600'>Faktura <u>{fileName}</u> została załadowana.</p>
                  <button onClick={handleSave} className={btnClass} disabled={isLoading}>
                    {isLoading ? 'Przetwarzanie...' : 'Zapisz'}
                  </button>
                </>
              )
              : (
                <form onSubmit={handleUploadPdf}>
                  <div className="my-3">
                    <input type="file" onChange={handleFileChange} accept="application/pdf" />
                  </div>
                  <button type="submit" className={btnClass} disabled={isLoading}>
                    {isLoading ? 'Przetwarzanie...' : 'Dodaj fakturę'}
                  </button>
                </form>
              )
          }
        </div>
      </div>
    </>
  );
}
