import { useParams } from "react-router-dom";

import React, { useEffect, useState } from 'react';
import InvoiceTable from './../components/Invoice/InvoiceTable';
import ListOrders from './../components/Invoice/ListOrders';

import axios from 'axios';


export default function Invoices() {
  const [tableData, setTableData] = useState([]);
  const [nameInvoice, setNameInvoice] = useState('');
  const [rateInvoice, setRateInvoice] = useState('');
  const [descriptionInvoice, setDescriptionInvoice] = useState('');
  const [deliveryInvoice, setDeliveryInvoice] = useState('');
  const [ordersSummary, setOrdersSummary] = useState([]);

  const { id } = useParams();

  const handleSave = (e) => {
    e.preventDefault();
    console.log(('dziala'));
  }

  const handleDescriptionChange = (newDescription) => {
    setDescriptionInvoice(newDescription);  
  };


  useEffect(() => {
    const fetchInvoice = async () => {
      try {    
        const axiosResponse = await axios.get(`${process.env.REACT_APP_SERVER_BACKEND}/faktura/${id}`);
        const { products, nameInvoice, delivery, rate, description } = axiosResponse.data;

        // console.log(axiosResponse.data.products);
        setTableData(products);

        // setTableData([products]); //tu chyba trzeba usunac ten obiekt i w tabeli zrezygnowac z pageruslts        
        setNameInvoice(nameInvoice);
        setDeliveryInvoice(delivery ? delivery.toFixed(2) : '0.00');
        setRateInvoice(rate ? rate.toFixed(2) : '0.00');

        setDescriptionInvoice(description); 
  
        const ordersSummaryGrouped = products
          .filter(product => product.product_order)
          .reduce((acc, product) => {
            const existingOrder = acc.find(order => order.zamowienie === product.product_order);
            if (existingOrder) {
              existingOrder.brutto = (parseFloat(existingOrder.brutto) + parseFloat(product.brutto)).toFixed(2);
            } else {
              acc.push({
                zamowienie: product.product_order,
                brutto: parseFloat(product.brutto).toFixed(2)
              });
            }
            return acc;
          }, []);
  
        setOrdersSummary(ordersSummaryGrouped);
      } catch (error) {
        console.error('Błąd podczas pobierania faktury:', error);
      }
    };
  
    fetchInvoice();
  }, [id]);
  
    
  return (
    <div className="flex">
      <InvoiceTable 
        nameInvoice={nameInvoice} 
        tableData={tableData} 
        ordersSummary={ordersSummary} 
        setOrdersSummary={setOrdersSummary}   
        setTableData={setTableData}
        descriptionInvoice={descriptionInvoice}
        setDescriptionInvoice={handleDescriptionChange}  
      />
      {/* <ListOrders ordersSummary={ordersSummary} tableData={tableData} /> */}
      <ListOrders 
        ordersSummary={ordersSummary} 
        saveInvoice={handleSave} 
        deliveryInvoice={deliveryInvoice} 
        rateInvoice={rateInvoice} 
        descriptionInvoice={descriptionInvoice} 
      />
    </div>
  );
}