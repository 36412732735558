import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const navigateTo = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [user, setUser] = useState(null); // Zarządzanie danymi użytkownika w jednym kontekście

    const loginAction = (loginEmail, loginPassword) => {
        return Axios.post(
            `${process.env.REACT_APP_SERVER_BACKEND}/login`,
            {
                LoginEmail: loginEmail,
                LoginPassword: loginPassword,
            },
            { withCredentials: true }
        ).then((res) => {
            if (res.data.message || loginEmail === '' || loginPassword === '') {
                throw new Error('Błąd logowania. Sprawdź dane.');
            } else {
                const userData = {
                    email: res.data.result[0].email,
                    role: res.data.result[0].role,
                };

                // Zapis tokenu i danych użytkownika
                setToken(res.data.token);
                setUser(userData);
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('role', userData.role);

                // Przekierowanie po zalogowaniu
                navigateTo('/kokpit');
            }
        });
    };

    const logoutAction = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigateTo('/login');
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logoutAction }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
