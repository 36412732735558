import React, { useState } from 'react';
import './LoginForm.scss';
import { useAuth } from '../../auth/AuthProvider'; // Poprawka: upewnij się, że używasz poprawnej ścieżki

const LoginForm = () => {
  const auth = useAuth();
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null); // Stan na komunikaty błędów

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginEmail && loginPassword) {
      auth.loginAction(loginEmail, loginPassword).catch(err => {
        setErrorMessage(err.message || 'Błąd logowania.'); // Ustaw komunikat błędu, jeśli wystąpił
      });
      return;
    }
    setErrorMessage('Błędne dane logowania.'); // Ustaw komunikat, jeśli dane są puste
  };

  return (
    <div className="flex flex-row flex-col h-screen justify-center items-center">
      <h1 className="text-center text-2xl mb-4">Karnisze</h1>
      <form onSubmit={handleSubmit} className='bg-white px-8 py-8 rounded-lg shadow-md'>
        {errorMessage && <div className="text-red-500 mb-3">{errorMessage}</div>} {/* Wyświetl komunikat o błędzie */}
        <div>
          <input 
            placeholder='E-mail' 
            type="email" 
            name="email" 
            className="w-full border-0 border-b border-gray-300 text-slate-900 bg-gray-50 p-3 text-base"
            onChange={(event) => setLoginEmail(event.target.value)} 
          />
        </div>
        <div className="mt-4">
          <input 
            placeholder='Hasło' 
            type="password" 
            name="password" 
            className="w-full border-0 border-b border-gray-300 text-slate-900 bg-gray-50 p-3 text-base"
            onChange={(event) => setLoginPassword(event.target.value)} 
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button 
            type="submit" // Upewnij się, że przycisk działa jako submit
            className="bg-orange-400 py-2 px-3 text-white font-medium rounded-md uppercase tracking-wider"
          >
            Zaloguj
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
