import React, { useEffect, useState } from 'react';

import Editor from 'react-simple-wysiwyg';

import axios from 'axios';
import InputOrder from './InputOrder';

import { tableTitleClass, btnClass } from '../../themes';

import { RiAddBoxLine, RiChatCheckFill } from "react-icons/ri";


const InvoiceTable = ({ nameInvoice, ordersSummary, setOrdersSummary, tableData, setTableData, descriptionInvoice, setDescriptionInvoice }) => {


    const inputClass = 'w-5/6 border border-gray-300 text-slate-900 bg-gray-50 p-3 text-base'

    const [productPrices, setProductPrices] = useState({});
    const [currentPage, setCurrentPage] = useState(1);  // Aktualna strona
    const itemsPerPage = 100;  // Liczba pozycji na stronę
    const [currentItems, setCurrentItems] = useState([]);

    const totalPages = Math.ceil(tableData.length / itemsPerPage);


    const [message, setMessage] = useState(null)
    const [isPopupOpen, setIsPopupOpen] = useState(false);    
    const [productDescription, setProductDescription] = useState('');
    const [productIdCurrent, setProductIdCurrent] = useState();


    // Funkcja do zmiany strony
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleChangeOrderNumber = (itemId, newOrderNumber) => {
        // Zaktualizuj currentItems
        // setCurrentItems((prevItems) => {
        //     return prevItems.map(item => {
        //         if (item.id === itemId) {
        //             return { ...item, product_order: newOrderNumber }; // Zaktualizuj product_order w currentItems
        //         }
        //         return item;
        //     });
        // });
    
        // Zaktualizuj tableData
        setTableData((prevData) => {
            return prevData.map(item => {
                if (item.id === itemId) {
                    return { ...item, product_order: newOrderNumber }; // Zaktualizuj product_order w tableData
                }
                return item;
            });
        });
    };

    const handleSaveDescription = (e) => {               
        const updatedTableData = [...tableData];
        const index = updatedTableData.findIndex(item => item.id === productIdCurrent);

        
        if(index !== -1) {
            updatedTableData[index].description = productDescription;
            setTableData(updatedTableData);
        }


        const updatedProduct = {
            product_description: productDescription
        }
        axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/produkt/${productIdCurrent}`, updatedProduct).then((res) => {
            setMessage('Zmiany zostały zapisane')
            setTimeout(() => setMessage(null), 2000);
        }).catch((error) => {
            console.log(error);
        });
    }

    const handlePopupDescription = (e) => { 
        const product_id = parseFloat(e.target.closest('tr').dataset.product_id);
        setProductIdCurrent(product_id);
        axios.get(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/produkt/${product_id}`).then((res) => {
            const productDesc = res.data.results[0].product_description; 

            if(productDesc) {
                setProductDescription(productDesc);
            } else {
                setProductDescription('');
            }
            setIsPopupOpen(true);
        }).catch((error) => {
            console.log(error);
        } );
    };
    
    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // setDescriptionInvoice(description);

        setCurrentItems(tableData.slice(indexOfFirstItem, indexOfLastItem));

        const fetchProductPrices = async () => {
            try {
                const promises = tableData.slice(indexOfFirstItem, indexOfLastItem).map(async (item) => {
                    const response = await axios.get(process.env.REACT_APP_SERVER_BACKEND + '/produkt', {
                        params: {
                            nazwa: item.nazwa
                        }
                    });
                    return { name: item.nazwa, price: response.data.price, stock: response.data.stock };
                });

                const results = await Promise.all(promises);

                const prices = {};
                results.forEach(result => {
                    prices[result.name] = result;
                });

                setProductPrices(prices);
            } catch (error) {
                console.error('Błąd przy pobieraniu cen produktów:', error);
            }
        };

        fetchProductPrices();
    }, [currentPage, tableData]);

    return (
        <>
            <div className="py-3 basis-9/12">
                <div className="rounded-xl shadow-sm px-3 py-8 bg-white text-gray-700">
                    <h1 className="text-2xl font-bold text-center mb-8 w-full border-b border-gray-700 pb-8">{nameInvoice}</h1>
                    <div className="mb-5">
                        <Editor value={descriptionInvoice} onChange={(e) => setDescriptionInvoice(e.target.value)} />
                    </div>
                    <table className="w-full text-gray-800">
                        <thead>
                            <tr className='text-xs text-center'>
                                <th className={tableTitleClass}>Lp.</th>
                                <th className={tableTitleClass}>Nazwa</th>
                                <th className={tableTitleClass}>Ilość</th>
                                <th className={tableTitleClass}>Jm</th>
                                <th className={tableTitleClass}>Cena netto</th>
                                <th className={tableTitleClass}>Podatek</th>
                                <th className={tableTitleClass}>Wartość netto</th>
                                <th className={tableTitleClass}>VAT</th>
                                <th className={tableTitleClass}>Brutto</th>
                                <th className={tableTitleClass}>Zamówienia</th>
                                <th className={tableTitleClass}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, itemIndex) => {
                                const backendPrice = productPrices[item.nazwa]?.price;

                                let priceNettoNumber;
                                if (typeof item.cena_netto === 'string') {
                                    priceNettoNumber = parseFloat(item.cena_netto.replace(',', '.')).toFixed(2);
                                } else if (typeof item.cena_netto === 'number') {
                                    priceNettoNumber = item.cena_netto.toFixed(2);
                                } else {
                                    priceNettoNumber = '0.00';
                                }

                                let orderNumber;
                                if(item.product_order) {
                                    orderNumber = item.product_order;
                                } else {
                                    orderNumber = '';
                                }

                                const rowClass = backendPrice
                                    ? (parseFloat(backendPrice) === parseFloat(priceNettoNumber)
                                        ? 'text-green-600'
                                        : 'text-red-600')
                                    : '';

                                return (
                                    <tr key={itemIndex} className={`text-sm text-center ${rowClass}`} data-product_id={item.id}>
                                        <td className={`w-px py-2 px-1 ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>                                    
                                            <input type="text" value={item.lp || ""} name="product_lp" disabled style={{ width: '30px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`text-left w-full py-2 px-3 ${itemIndex % 2 === 1 ? ' bg-gray-100' : ''}`}>
                                            <input type="text" value={item.nazwa || ""} name="product_name" disabled style={{ width: '100%', background: 'transparent' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <input type="text" value={item.ilosc || ""} name="product_count" disabled style={{ width: '20px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <input type="text" value={item.jm || ""} name="product_unit" disabled style={{ width: '20px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <span className="product_price_net">
                                                {typeof item.cena_netto === 'string'
                                                    ? parseFloat(item.cena_netto.replace(',', '.')).toFixed(2)
                                                    : (typeof item.cena_netto === 'number'
                                                        ? item.cena_netto.toFixed(2)
                                                        : '0.00')}
                                            </span>
                                            {backendPrice && parseFloat(backendPrice) !== parseFloat(priceNettoNumber) && (
                                                <span className='block'>
                                                    <span className="text-xs">({parseFloat(backendPrice).toFixed(2)})</span>
                                                </span>
                                            )}
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <input type="text" value={item.podatek || ""} name="product_tax" disabled style={{ width: '20px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <input type="text" value={typeof item.wartosc_netto === 'string'
                                                ? parseFloat(item.wartosc_netto.replace(',', '.')).toFixed(2)
                                                : (typeof item.wartosc_netto === 'number'
                                                    ? item.wartosc_netto.toFixed(2)
                                                    : '0.00')} name="product_value_net" disabled style={{ width: '50px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <input type="text" value={typeof item.vat === 'string'
                                                ? parseFloat(item.vat.replace(',', '.')).toFixed(2)
                                                : (typeof item.vat === 'number'
                                                    ? item.vat.toFixed(2)
                                                    : '0.00')} name="product_vat" disabled style={{ width: '50px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 whitespace-nowrap ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`} data-name="brutto">
                                            <input type="text" value={typeof item.brutto === 'string'
                                                ? parseFloat(item.brutto.replace(',', '.')).toFixed(2)
                                                : (typeof item.brutto === 'number'
                                                    ? item.brutto.toFixed(2)
                                                    : '0.00')} name="product_brutto" disabled style={{ width: '70px', background: 'transparent', textAlign: 'center' }} />
                                        </td>
                                        <td className={`w-px py-2 px-3 ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            <InputOrder
                                                ordersSummary={ordersSummary}
                                                setOrdersSummary={setOrdersSummary}
                                                initialOrderNumber={orderNumber}
                                                onChangeOrderNumber={(newOrderNumber) => handleChangeOrderNumber(item.id, newOrderNumber)}
                                            />
                                        </td>
                                        <td className={`w-px py-2 pr-2 ${itemIndex % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                            {item.description ? (
                                                <RiChatCheckFill className="text-green-500 cursor-pointer" onClick={handlePopupDescription} />
                                            ) : (
                                                <RiAddBoxLine className="cursor-pointer" onClick={handlePopupDescription} />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        
                    </table>
                    <div className="pagination mt-4 flex justify-center flex-wrap">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={`m-1 px-3 py-1 border rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            {isPopupOpen && 
            // hidden
                (<div id="modelConfirm" className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 ">
                    <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">

                        <div className="flex justify-end p-2">
                            <button onClick={(e) => setIsPopupOpen(false) } type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                        <div className="p-6 pt-0 text-center">     
                            {message && (
                                <div className="bg-green-200 text-green-800 p-4 rounded mb-4">
                                    {message}
                                </div>
                            )}    
                            <textarea name="product_description" id="product_description" defaultValue={productDescription || ''} className={inputClass} onChange={(e) => setProductDescription(e.target.value)}>
                            </textarea>
                            <button className={btnClass} onClick={handleSaveDescription}>Zapisz zmiany</button>
                        </div>

                    </div>
                </div>)
            }
        </>
    );
};

export default InvoiceTable;
