import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom";

import LoginForm from "./components/LoginForm/LoginForm"
import AuthProvider, { useAuth } from './auth/AuthProvider';
import LayoutAdmin from './LayoutAdmin';

import Kokpit from './views/Kokpit';
import InvoicesList from './views/InvoicesList';
import InvoiceAdd from './views/InvoiceAdd';
import InvoiceEdit from './views/InvoiceEdit';
import ProductsList from './views/ProductsList';
import ProductAdd from './views/ProductsAdd';
import ProductEdit from './views/ProductsEdit';
import ProductCSV from './views/ProductsCSV';

import ErrorPage from './ErrorPage';

import './scss/index.scss';

// Komponent sprawdzający, czy użytkownik jest zalogowany
const PrivateRoute = ({ children, role }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (role && user.role !== role) {
        return <Navigate to="/error" />;
    }

    return children;
};

function App() {
    return (
        <div className="App">
            <Router>
                <AuthProvider> {/* Jeden provider dla tokena i użytkownika */}
                    <Routes>
                        <Route path="/login" element={<LoginForm />} />
                        
                        <Route element={<LayoutAdmin />}>
                            <Route path="/" element={<PrivateRoute><Kokpit /></PrivateRoute>} />
                            <Route path="/kokpit" element={<PrivateRoute><Kokpit /></PrivateRoute>} />

                            <Route path="/faktury" element={<PrivateRoute><InvoicesList /></PrivateRoute>}>
                                <Route path=":pageNumber" element={<PrivateRoute><InvoicesList /></PrivateRoute>} />
                            </Route>

                            <Route path="/faktury/dodaj" element={<PrivateRoute><InvoiceAdd /></PrivateRoute>} />
                            <Route path="/faktury/edytuj/:id" element={<PrivateRoute><InvoiceEdit /></PrivateRoute>} />
                            <Route path="/produkty" element={<PrivateRoute><ProductsList /></PrivateRoute>}>
                                <Route path=":pageNumber" element={<PrivateRoute><ProductsList /></PrivateRoute>} />
                            </Route>
                            <Route path="/produkty/dodaj" element={<PrivateRoute><ProductAdd /></PrivateRoute>} />
                            <Route path="/produkty/csv" element={<PrivateRoute><ProductCSV /></PrivateRoute>} />
                            <Route path="/produkty/edytuj/:id" element={<PrivateRoute><ProductEdit /></PrivateRoute>} />
                            {/* <Route path="/produkty/edytuj/:id" element={<PrivateRoute role="admin"><ProductEdit /></PrivateRoute>} /> */}
                        </Route>

                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
