import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { btnClass, btnClassRed } from "../../themes";
import Axios from "axios";
import { useAuth } from './../../auth/AuthProvider';

const ListOrders = ({ ordersSummary, saveInvoice, deliveryInvoice, rateInvoice, descriptionInvoice }) => {
    const ordersSummaryTotal = ordersSummary.reduce((total, item) => total + parseFloat(item.brutto), 0).toFixed(2);

    const { user } = useAuth();
    const { id } = useParams();

    const [invoiceRate, setInvoiceRate] = useState('0,00')
    const [invoiceDelivery, setInvoiceDelivery] = useState('0,00')

    useEffect(() => {
        setInvoiceDelivery(deliveryInvoice);
        setInvoiceRate(rateInvoice);
    }, [deliveryInvoice, rateInvoice]);    

    
    const updateInvoice = (e) => {
        e.preventDefault();

        const updatedInvoice = {
            delivery: invoiceDelivery === '' ? '0.00' : invoiceDelivery,
            rate: invoiceRate === '' ? '0.00' : invoiceRate,
            description: descriptionInvoice || ''
        }
        console.log(updatedInvoice)

        Axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/faktury/edytuj/${id}`, updatedInvoice)
        .then((res) => {
            const info = document.createElement('div');
            info.classList.add('bg-green-100', 'fixed', 'bottom-3', 'right-3', 'p-4', 'text-black')
            info.innerHTML = 'Zmiany zostały zapisane.';
            document.body.appendChild(info);
            setTimeout(() => {
                document.body.removeChild(info);
            }, '1000');
            console.log(res)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const hideOrders = (e) => {
        const isChecked = e.target.checked;
        const orders = document.querySelectorAll('input[name="orderChecked"]');
        if(isChecked) {
            let firstUnchecked = true; 
            orders.forEach((order) => {
                if(order.checked) {
                    order.closest('tr').style.display = 'none';
                } else if(firstUnchecked) {
                    order.closest('tr').style.display = '';
                    firstUnchecked = false
                } else {
                    order.closest('tr').style.display = 'none';
                }
            });
        } else {
            orders.forEach((order) => {
                order.closest('tr').style.display = '';
            })
        }
    }

    const removeInvoice = (e, id) => {
        e.preventDefault();   
        if (window.confirm("Potwierdź usunięcie faktury")) { 
            Axios.delete(
                `${process.env.REACT_APP_SERVER_BACKEND}/faktury/${id}`
            ).then((res) => { 
                if(res.data.success) { 
                    Axios.get(
                        process.env.REACT_APP_SERVER_BACKEND + '/faktury',
                    ).then((res) => {
                        alert("Faktura usunięta");
                        window.location.href = `/faktury/`;
                    });
                } else {
                    alert('Brak uprawnień do usunięcia faktury');
                }
            });
        }
    }

    const handleChangeOrderChecked = (e) => {
        const rowCells = e.target.closest('tr').querySelectorAll('td');
        if(e.target.checked) { 
            rowCells.forEach(cell => cell.classList.add('bg-yellow-100'));
        } else {
            rowCells.forEach(cell => cell.classList.remove('bg-yellow-100'));
        }
    }

    const copyOrder = (e) => {
        const copyText = e.target.innerHTML;

        navigator.clipboard.writeText(copyText).then(() => {
            const info = document.createElement('div');
            // info.classList.add('bg-red-100');
            info.classList.add('bg-green-100', 'fixed', 'bottom-3', 'right-3', 'p-4', 'text-black')
            info.innerHTML = '<strong>Skopiowano:</strong> ' + copyText;

            // const infoContent = document.createTextNode('Skopiowano' + copyText);

            // info.appendChild(infoContent);

            document.body.appendChild(info);

            setTimeout(() => {
            document.body.removeChild(info);

            }, '1000');
            
        });
    }
 

    return ( 
        <div className="pl-4 basis-3/12">
            <div className="sticky top-3">
                <div className="rounded-xl shadow-sm p-4 bg-white text-gray-700 rounded">
                    <h2 className="text-lg text-center mb-2 font-bold">Lista zamówień</h2>
                    <table className="w-full text-gray-800 text-center text-sm">
                        <thead>
                        <tr>
                            <th className="bg-slate-200 py-2 px-2">Lp.</th>
                            <th className="bg-slate-200 py-2 px-2">Zamówienie</th>
                            <th className="bg-slate-200 py-2 px-2">Wartość Brutto</th>
                            <th className="bg-slate-200 py-2 px-2"></th>
                        </tr>
                        </thead>
                        <tbody>
                            {ordersSummary.map((item, index) => (
                            <tr key={index} className="cursor-pointer">
                                <td className={`py-2 px-2 ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>{index + 1}</td>
                                <td onClick={copyOrder} className={`order-name py-2 px-2 ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>GG {item.zamowienie}</td>
                                <td onClick={copyOrder} className={`order-brutto py-2 px-2 ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>{item.brutto}</td>
                                <td className={`py-2 px-2 ${index % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                    <input 
                                        type="checkbox"
                                        name="orderChecked" 
                                        onChange={handleChangeOrderChecked}
                                    />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className="font-bold">
                                <td colSpan="2" className="py-2 px-2 bg-slate-200">Razem</td>
                                <td className="bg-slate-200 py-2 px-2">
                                    {ordersSummaryTotal}
                                </td>
                                <td className="py-2 px-2 bg-slate-200"></td>
                            </tr>
                            {(invoiceDelivery && invoiceDelivery !== 0.00) &&
                                <tr className="font-bold">
                                    <td colSpan="2" className="py-2 px-2 bg-slate-200 border-t border-white">Z dostawą</td>
                                    <td className="bg-slate-200 py-2 px-2 border-t border-white">
                                        {(parseFloat(ordersSummaryTotal) + parseFloat(invoiceDelivery)).toFixed(2)}
                                    </td>
                                    <td className="py-2 px-2 bg-slate-200 border-t border-white"></td>
                                </tr>
                            }
                        </tfoot>
                    </table>
                    <div className="p-2 mt-2">
                        <label htmlFor="hideOrders" className="text-sm cursor-pointer"><input type="checkbox" name="hideOrders" id="hideOrders" className="mr-1" onChange={hideOrders} /> <span>Ukryj zamówienia zaznaczone</span></label>
                    </div>
                </div>
                <div className="rounded-xl shadow-sm p-4 bg-white text-gray-700 mt-4">
                    <div className="flex">
                        <table className="w-full text-gray-800 text-sm">
                            <tbody>
                                <tr>
                                    <th className="bg-slate-200 py-2 px-2 w-1/2 border-b border-white">Koszt dostawy</th>
                                    <td className="bg-white p-2 text-center whitespace-nowrap border-b border-white">
                                        <input 
                                            type="number" 
                                            name="delivery"
                                            id="delivery"
                                            className="border border-gray-300 text-slate-900 bg-gray-50 w-full p-1 text-xs text-center"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                value = value.replace(/[^0-9.,]/g, '');
                                                value = value.replace(',', '.');
                                                const regex = /^\d*(\.\d{0,2})?$/;                                        
                                                if (regex.test(value)) {
                                                    setInvoiceDelivery(value);
                                                }
                                            }}
                                            value={invoiceDelivery || '0.00'}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="bg-slate-200 py-2 px-2 w-1/2">Kurs dolara</th>
                                    <td className="bg-gray-100 p-2 text-center whitespace-nowrap">
                                        <input 
                                            type="number" 
                                            name="rate"
                                            id="rate"
                                            className="border border-gray-300 text-slate-900 bg-gray-50 w-full p-1 text-xs text-center"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                value = value.replace(/[^0-9.,]/g, '');
                                                value = value.replace(',', '.');
                                                const regex = /^\d*(\.\d{0,2})?$/;                                        
                                                if (regex.test(value)) {
                                                    setInvoiceRate(value);
                                                }
                                            }}
                                            value={invoiceRate || '0.00'}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {user.role === 'admin' && (
                <div className="rounded-xl shadow-sm px-4 py-6 bg-white text-gray-700 rounded mt-4">
                    <ul className="flex justify-center">
                        {id ? (
                            <>
                                <li className="mx-2">
                                    <a href="#/" className={btnClass} onClick={updateInvoice}>Zaktualizuj</a>
                                </li>
                                <li className="mx-2">
                                    <a href="#/" className={btnClassRed} onClick={(e) => removeInvoice(e, id)}>Usuń</a>
                                </li>
                            </>
                        ) : (
                            <li className="mx-2">
                                <a href="#/" className={btnClass} onClick={saveInvoice}>Zapisz</a>
                            </li>
                        )}
                    </ul>
                </div>
                )}
            </div>
        </div>
    )
}

export default ListOrders;