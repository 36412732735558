import React from 'react'
import { Link } from 'react-router-dom'
import { btnClass } from '../themes'

import { useAuth } from './../auth/AuthProvider';

export default function Kokpit() {
  const { user } = useAuth();
  return (
    <div className="h-lvh flex justify-center flex-col"> 
      {user ? (
        <p>Witaj, {user.email}!</p>
      ) : (
        <p>Nie jesteś zalogowany</p>
      )}

      <h1 className="text-2xl text-center">Wybierz co chcesz zrobić:</h1>
      <ul className="flex items-center justify-center bg-white rounded-lg px-4 py-8 mt-4">
        <li>
          <Link to={'/faktury'} className={`${btnClass} m-2`}>Dodać fakturę</Link>
        </li>
        <li>
          <Link to={'/produkty/dodaj'} className={`${btnClass} m-2`}>Dodać produkt</Link>
        </li>
      </ul>
    </div>
  );
}
